import { httpApi } from 'boot/axios'

export const sysUser = {
  path: 'sys-user',
  login: function (data) {
    return httpApi.post(this.path + '/login', data)
  },
  info: function () {
    return httpApi.get(this.path + '/info')
  },
  // sys-user/list
  list: function (data) {
    return httpApi.post(this.path + '/list', data)
  },
  // create
  create: function (data) {
    return httpApi.post(this.path + '/create', data)
  },
  // modify
  modify: function (data) {
    return httpApi.post(this.path + '/modify', data)
  },
}

export const supplier = {
  path: 'supplier',
  get: function (data) {
    return httpApi.get(this.path, {
      params: data
    })
  },
  add: function (data) {
    return httpApi.post(this.path, data)
  },
  alipay: function (data) {
    return httpApi.get(this.path + '/alipay', {
      params: data
    })
  },
  alipaySave: function (data) {
    return httpApi.post(this.path + '/alipay', data)
  },
  price: function (data) {
    return httpApi.get(this.path + '/price', {
      params: data
    })
  },
  priceSave: function (data) {
    return httpApi.post(this.path + '/price', data)
  },
  disabledPrice: function (data) {
    return httpApi.post(this.path + '/price/disabled', data)
  },
  delPrice: function (id) {
    return httpApi.delete(this.path + '/price/' + id)
  },
  getSupplier: function (id) {
    return httpApi.get(this.path + '/' + id)
  },
  taskTypeGet: function (data) {
    return httpApi.get(this.path + '/task-type', {
      params: data
    })
  },
  taskTypeSave: function (data) {
    return httpApi.post(supplier.path + '/task-type', data)
  },
  taskTypeSelect: function (data) {
    return httpApi.get(supplier.path + '/task-type/select', {
      params: data
    })
  },
  supplierTaskTypeSave: function (data) {
    return httpApi.post(this.path + '/task-type-save', data)
  },
  ability: function (data) {
    return httpApi.get(supplier.path + '/ability', {
      params: data
    })
  },
  abilitySave: function (data) {
    return httpApi.post(supplier.path + '/ability', data)
  },
  abilityDel: function (id) {
    return httpApi.delete(supplier.path + `/ability/${id}`)
  },
  stopDispatch: function (data) {
    return httpApi.post(supplier.path + '/stop-dispatch', data)
  },
  supplierWithdraw: function () {
    return httpApi.get(supplier.path + '/withdraw')
  },
  supplierWithdrawCheck: function (data) {
    return httpApi.post(supplier.path + '/withdraw/check', data)
  }
}

export const uploadFile = function (data) {
  return httpApi.post('/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export const businesses = {
  path: 'businesses',
  get: function (data) {
    return httpApi.get(this.path, {
      params: data
    })
  },
  // POST /manage/businesses/changeWxRobotAutoPush
  changeWxRobotAutoPush: function (data) {
    return httpApi.post(businesses.path + '/changeWxRobotAutoPush', data)
  },
  one: function (id) {
    return httpApi.get(this.path + '/' + id)
  },
  save: function (data) {
    return httpApi.post(this.path, data)
  },
  code: function (data) {
    return httpApi.get(businesses.path + '/code', {
      params: data
    })
  },
  saveCode: function (data) {
    return httpApi.post(businesses.path + '/code', data)
  },
  delCode: function (id) {
    return httpApi.delete(businesses.path + `/code/${id}`)
  },
  disabled: function (data) {
    return httpApi.post(businesses.path + '/disabled', data)
  },
  credit: function (data) {
    return httpApi.get(businesses.path + '/credit', {
      params: data
    })
  }, // 提示缴纳保证金
  specialAccountMargin: function (data) {
    return httpApi.post(businesses.path + '/special-account-margin', data)
  },
  creditRecord: function (data) {
    return httpApi.get(businesses.path + '/credit/record', {
      params: data
    })
  },
  advanceAdjustment: function (data) {
    return httpApi.post(businesses.path + '/advance-adjustment', data)
  },
  specialAccount: function (data) {
    return httpApi.get(businesses.path + '/special-account', {
      params: data
    })
  },
  specialAccountSave: function (data) {
    return httpApi.post(businesses.path + '/special-account', data)
  },
  vip: function (data) {
    return httpApi.get(businesses.path + '/vip', {
      params: data
    })
  },
  vipSave: function (data) {
    return httpApi.post(businesses.path + '/vip', data)
  },
  vipDel: function (id) {
    return httpApi.delete(businesses.path + `/vip/${id}`)
  },
  vipBuy: function (data) {
    return httpApi.get(businesses.path + '/vip/buy', {
      params: data
    })
  },
  alipayEmployee: function (data) {
    return httpApi.get(businesses.path + '/alipay-employee', {
      params: data
    })
  },
  alipayEmployeeAdd: function (data) {
    return httpApi.post(businesses.path + '/alipay-employee', data)
  },
  /**
   * 23-12-28
   * 获取当前用户要变更的管理员人员列表
   */
  getChangePersonnelList: function (id) {
    return httpApi.get(`${businesses.path}/admin/change/${id}`)
  },
  /**
   * 23-12-28
   * 提交变更管理员
   */
  subChangeAdmin: function (params) {
    return httpApi.post(`${businesses.path}/admin/change`, params)
  },
  /**
   * 获取微信绑定的url地址
   */
  redirectUri: function ({ id, ...rest }) {
    return httpApi.get(this.path + '/redirect/uri/' + id, {
      params: rest
    })
  },
  /**
   * 绑定微信
   * @param data
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  wechatBind: function (data) {
    return httpApi.post(this.path + '/wechat/bind', data)
  },
  /**
   * 解除微信绑定
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  wechatUnbind: function (id) {
    return httpApi.delete(this.path + '/wechat/unbind/' + id)
  }
}

export const config = {
  path: 'config',
  saleRules: function () {
    return httpApi.get(this.path + '/sale-rules')
  },
  // {{host}}/config/money-rule/delete
  moneyRuleDelete: function (data) {
    return httpApi.post(this.path + '/money-rule/delete', data)
  },
  // {{host}}/config/money-rule/modify
  moneyRuleModify: function (data) {
    return httpApi.post(this.path + '/money-rule/modify', data)
  },
  saleRulesPost: function (data) {
    return httpApi.post(this.path + '/sale-rules', data)
  },
  defaultServiceAmount: function () {
    return httpApi.get(this.path + '/default-service-amount')
  },
  grabTicketLadderPrice: function () {
    return httpApi.get(this.path + '/grab-ticket-ladder-price')
  },
  addGrabTicketLadderPrice: function (data) {
    return httpApi.post(this.path + '/grab-ticket-ladder-price-create', data)
  },
  grabTicketLadderPriceDel: function (data) {
    return httpApi.post(this.path + '/grab-ticket-ladder-price-del', data)
  },
  defaultServiceAmountSave: function (data) {
    return httpApi.post(this.path + '/default-service-amount', data)
  },
  advanceCode: function (data) {
    return httpApi.get(this.path + '/advance-code', {
      params: data
    })
  },
  advanceCodeSave: function (data) {
    return httpApi.post(config.path + '/advance-code', data)
  },
  reasonGet: function (data) {
    return httpApi.get(config.path + '/reason', {
      params: data
    })
  },
  reasonPost: function (data) {
    return httpApi.post(config.path + '/reason', data)
  },
  reasonDel: function (id) {
    return httpApi.delete(config.path + `/reason/${id}`)
  },
  ticketLabel: function () {
    return httpApi.get(config.path + '/ticket-label')
  },
  ticketLabelSave: function (data) {
    return httpApi.post(config.path + '/ticket-label', data)
  },
  ticketLabelDel: function (id) {
    return httpApi.delete(config.path + `/ticket-label/${id}`)
  },
  alipayEmployee: function () {
    return httpApi.get(config.path + '/alipay-employee/list')
  },
  alipayEmployeeMoneyRuleJoin: function (form) {
    return httpApi.post(config.path + '/alipay-employee/money-rule', form)
  },
  alipayEmployeeSave: function (form) {
    return httpApi.post(config.path + '/alipay-employee/save', form)
  },
  alipayEmployeeDel: function (id) {
    return httpApi.delete(config.path + `/alipay-employee/${id}`)
  },
  moneyRules: function (data) {
    return httpApi.get(config.path + '/money-rule/list', {
      params: data
    })
  },
  moneyRuleSave: function (data) {
    return httpApi.post(config.path + '/money-rule/create', data)
  },
  personnel: function (data) {
    return httpApi.get(config.path + '/personnel', {
      params: data
    })
  },
  personnelSave: function (data) {
    return httpApi.post(config.path + '/personnel', data)
  },
  setting: function () {
    return httpApi.get(config.path + '/setting')
  },
  settingSave: function (data) {
    return httpApi.post(config.path + '/setting', data)
  },
  settingDel: function (id) {
    return httpApi.delete(config.path + `/setting/${id}`)
  }
}

export const funding = {
  path: 'funding-side',
  items: function (data) {
    return httpApi.get(this.path + '/list', {
      params: data
    })
  },
  save: function (data) {
    return httpApi.post(this.path + '/save', data)
  }
}

export const order = {
  path: 'order',
  list: function (data) {
    return httpApi.get(order.path + '/list')
  }
}

export const goods = {
  path: 'goods',
  list: function (data) {
    return httpApi.post(goods.path + '/getGoodsList', data)
  },
  // {{host}}/goods/saveGoods
  saveGoods: function (data) {
    return httpApi.post(goods.path + '/saveGoods', data)
  },
  // {{host}}/goods/deleteGoods
  deleteGoods: function (data) {
    return httpApi.post(goods.path + '/deleteGoods', { id: data })
  },
}

export const packages = {
  path: 'package',
  list: function (data) {
    return httpApi.post(packages.path + '/getPackageList', data)
  },
  // {{host}}/package/savePackage
  saveGoods: function (data) {
    return httpApi.post(packages.path + '/savePackage', data)
  },
  // {{host}}/goods/deleteGoods
  deleteGoods: function (data) {
    return httpApi.post(packages.path + '/deletePackage', { id: data })
  },
}

export const distributor = {
  path: 'distributor',
  list: function (data) {
    return httpApi.get(distributor.path, {
      params: data
    })
  },
  save: function (data) {
    return httpApi.post(distributor.path, data)
  },
  // {{host}}/distributor/addDistributorRunningWater
  addDistributorRunningWater: function (data) {
    return httpApi.post(distributor.path + '/addDistributorRunningWater', data)
  },
  // {{host}}/distributor/getDistributorRunningWaterList
  getDistributorRunningWaterList: function (data) {
    return httpApi.post(distributor.path + '/getDistributorRunningWaterList', data)
  }
}
