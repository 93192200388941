export const distributors = {
  path: 'distributor',
  name: 'DistributorManagement',
  component: () => import('pages/DistributorManagement/DistributorManagementView.vue'),
  meta: {
    title: '分销管理',
    icon: 'stacked_bar_chart',
    showSubMenu: false
  },
  redirect: '/admin/distributor/distributors',
  children: [{
    path: 'distributors',
    name: 'DistributorItems',
    component: () => import('pages/DistributorManagement/DistributorItems.vue'),
    meta: {
      title: '分销商列表',
      icon: 'people_alt',
      parent: 'DistributorManagement',
      menu: true
    }
  }]
}
