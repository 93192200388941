import { boot } from 'quasar/wrappers'
import axios from 'axios'
import { LocalStorage } from 'quasar'
import { httpConfig } from 'src/sliver/config'

const apiUrl = process.env.DEBUGGING ? process.env.DEV_API_URL : process.env.API_URL

const httpApi = axios.create({ baseURL: apiUrl })
httpApi.interceptors.request.use((config) => httpConfig(config))
export default boot(({
  app,
  router
}) => {
  httpApi.interceptors.response.use(function (response) {
    const data = response.data
    if (data.code === 401) {
      LocalStorage.remove('token')
      router.push({
        name: 'LoginPage'
      })
    }
    return data
  }, function (error) {
    return Promise.reject(error)
  })
  app.config.globalProperties.$axios = axios
  app.config.globalProperties.$httpApi = httpApi
})

export { httpApi, apiUrl }
