import { adminRouters } from 'src/router/admin'

const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'LoginPage',
        path: '',
        component: () => import('pages/LoginPage.vue')
      }
    ]
  },
  adminRouters,
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/wechat-bind',
    component: () => import('pages/WechatBindPage.vue'),
    name: 'WechatBindPage',
    meta: {
      title: '绑定微信账号'
    }
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
