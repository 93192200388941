import Swal from 'sweetalert2'

export const strNull = function (str) {
    if (str !== undefined && str !== null) {
      str = strRemoveSpace(str)
    }
    return typeof str === 'undefined' || str == null || str === ''
  }, strRemoveSpace = function (str) {
    return str.replace(/\s*/g, '')
  }

/**
 * 小型弹窗
 * @param text
 * @param type
 */
export const customTips = function (text, type) {
  if (type === 's') {
    type = 'success'
  }
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  Toast.fire({
    icon: type,
    title: text
  })
}
export const isValidPhoneNumber = function (phoneNumber) {
  const pattern = /^1[3-9]\d{9}$/
  return pattern.test(phoneNumber)
}
export const checkPassword = function (text) {
  const regex = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/
  return regex.test(text)
}

export const customAlert = function (text, type, title = '操作提示') {
  if (type === 'w') {
    type = 'warning'
  } else if (type === 's') {
    type = 'success'
  } else if (type === 'e') {
    type = 'error'
  }
  Swal.fire({
    text,
    icon: type,
    title,
    confirmButtonText: '<span class="q-btn__content text-center col items-center q-anchor--skip justify-center row"><span class="block">确认</span></span>',
    customClass: {
      confirmButton: 'q-btn q-btn-item non-selectable no-outline q-btn--push q-btn--rectangle bg-secondary text-white q-btn--actionable q-focusable q-hoverable glossy'
    },
    buttonsStyling: false
  })
}

export const customCF = function (text, cf) {
  Swal.fire({
    title: '操作确认',
    html: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: '<span class="q-btn__content text-center col items-center q-anchor--skip justify-center row"><span class="block">确认</span></span>',
    cancelButtonText: '<span class="q-btn__content text-center col items-center q-anchor--skip justify-center row"><span class="block">取消</span></span>',
    customClass: {
      confirmButton: 'customCF q-btn q-btn-item non-selectable no-outline q-btn--push q-btn--rectangle bg-secondary text-white q-btn--actionable q-focusable q-hoverable glossy',
      cancelButton: 'q-btn q-btn-item non-selectable no-outline q-btn--outline q-btn--rectangle text-negative q-btn--actionable q-focusable q-hoverable'
    },
    buttonsStyling: false
  }).then((result) => {
    if (result.isConfirmed) {
      cf()
    }
  })
}

export const createFormData = function (data) {
  const formData = new FormData()
  const appendFormData = (formData, data, parentKey = '') => {
    Object.entries(data).forEach(([key, value]) => {
      const newKey = parentKey ? `${parentKey}.${key}` : key
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          appendFormData(formData, item, `${newKey}[${index}]`)
        })
      } else if (typeof value === 'object' && value !== null) {
        appendFormData(formData, value, newKey)
      } else {
        formData.append(newKey, value)
      }
    })
  }
  appendFormData(formData, data)
  return formData
}
