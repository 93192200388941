import { defineStore } from 'pinia'
import { sysUser } from 'src/sliver/api'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    userInfo: {
      nickname: '',
      username: ''
    },
    login: false,
    token: ''
  }),

  getters: {
    doubleCount (state) {
      return state.counter * 2
    }
  },

  actions: {
    justLogin: function () {
      return this.login
    },
    getInfo: function () {
      const self = this
      sysUser.info().then(function (res) {
        if (res.code === 200) {
          self.userInfo = res.data
        }
      })
    }
  }
})
