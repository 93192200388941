import { boot } from 'quasar/wrappers'
import { useUserStore } from 'stores/users'

export default boot(async ({
  app,
  router
}) => {
  const user = useUserStore()
  user.getInfo()
})
