<template>
    <router-view/>
</template>

<style>
@import url("css/tailwindcss/output.css");
</style>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App'
})
</script>
