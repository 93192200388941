import axios from 'axios'
import { LocalStorage } from 'quasar'
export const appUrl = process.env.DEBUGGING ? process.env.DEV_URL : process.env.URL
export const httpConfig = async (config) => {
  if (process.env.DEBUGGING) {
    try {
      const res = await axios.get('/env.json')
      const data = res.data
      if (data.DEV === true) {
        config.baseURL = data.API_URL
        window.sliverEnv = data
      }
    } catch (e) {
      console.log(e)
    }
  }
  config.headers['Tp-Token'] = LocalStorage.getItem('token')
  return config
}
