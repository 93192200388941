import { boot } from 'quasar/wrappers'
import { LocalStorage } from 'quasar'
import { strNull } from 'src/sliver/tool'

export default boot(async ({
  app,
  router
}) => {
  router.beforeEach((to, from, next) => {
    const token = LocalStorage.getItem('token')
    if (strNull(token) && to.name !== 'LoginPage') {
      next('/')
    } else {
      next()
    }
  })
})
